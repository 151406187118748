    <a [routerLink]="link" class="ci-img" [class.hoverable]="hoverable" [style.background-image]="getMainImg">
        <div class="ci-img2" *ngIf="getItem().pictures[1]" [style.background-image]="getSecondaryImg"></div>
        <wishlist-toggle [item]="item" [isOnSingleItem]="false"></wishlist-toggle>
    </a>
    <div class="ci-sizes" *ngIf="item.sizes && item.sizes.length">
      <div class="ci-size" [class.out-of-stock]="s.q < 1" *ngFor="let s of item.sizes;">
        {{s.name}}
      </div>
    </div>
    <div class="ci-name" [class.compact]="compact">
        <a [routerLink]="link">{{getItem().name[currentLang.slug]}}</a>
    </div>
    <div class="ci-bottom">
      <div class="ci-price" [class.compact]="compact" [class.discounted]="item.price != item.original_price">
        <ng-container *ngIf="getItem().price != getItem().original_price">
          <span class="original_price">{{getItem().original_price}} {{currentCurrency.name}}</span>
        </ng-container>
        {{price}}

        {{currentCurrency.name}}
      </div>
      <div *ngIf="getItem().status" class="ci-status">{{getItem().status.name[currentLang.slug]}}</div>
      <div *ngIf="!getItem().status && getItem().sizeStatus" class="ci-status">{{getItem().sizeStatus.name[currentLang.slug]}}</div>
    </div>
    <div class="ci-colors" *ngIf="item.variations && item.variations.length">
        <div
            class="ci-color"
            *ngFor="let v of item.variations;"
            [class.active]="v.current"
            (click)="activateVariation(v)"
        >
            <div class="ci-color-wrap" *ngIf="v.color">
                <div class="ci-color-content" [style.background-color]="v.color.code"></div>
            </div>
        </div>
    </div>

