import { Component, Input, OnChanges, SimpleChanges, OnInit, ViewChild, ElementRef, AfterViewInit, HostListener, Output, EventEmitter } from "@angular/core";

import { IPicture } from 'src/app/model/orm/picture.interface';
import { AppService } from 'src/app/services/app.service';

@Component({
    selector: "gal-itempictures",
    templateUrl: "./galitempictures.component.html",
    styleUrls: ["./galitempictures.component.scss"]
})
export class GalItemPicturesComponent implements OnChanges, OnInit, AfterViewInit {
    @Input() pictures: IPicture[];
    @Output() pictureClicked = new EventEmitter<number>();
    @Output() pictureChanged = new EventEmitter<number>();
    public xl: IPicture[] = [];    
    private n: number = 0;
    public w: number = 0;    
    private winWidth: number = 0;
    @ViewChild("galwrap", {static: false}) galwrapRef: ElementRef;
    @ViewChild("galcontainer", {static: false}) galcontainerRef: ElementRef;
    private galwrap: HTMLElement = null;
    private galcontainer: HTMLElement = null;
    public dataReady: boolean = false;
    public layoutReady: boolean = false;
    // movement
    public step: number = 0;
    public left: number = 0;
    public active: boolean = false;    
    public moving: boolean = false;
    private startX: number = 0;
    private startXOffsetted: number = 0;
    public startY: number = 0;
    private prevX: number = 0;
    private canClick = true;

    constructor(private appService: AppService) {}

    get isBrowser(): boolean {return this.appService.isBrowser;}
    get isServer(): boolean {return this.appService.isServer;}

    public ngOnChanges(changes: SimpleChanges): void {
        // reinit only, not first time
        this.dataReady ? this.initData() : null; 
        this.layoutReady ? this.initLayout() : null;
    }

    public ngOnInit(): void {
        this.onDragMove = this.onDragMove.bind(this);
        this.onDragEnd = this.onDragEnd.bind(this);
        this.onTouchMove = this.onTouchMove.bind(this); 
        this.initData();
    }
    public onPictureClicked(i) {
        if (this.canClick) {
            this.pictureClicked.emit(i);
        }
        this.canClick = true;
    }

    public ngAfterViewInit(): void {
        if (this.isBrowser) {
            setTimeout(() => {
                this.galwrap = this.galwrapRef.nativeElement;
                this.galcontainer = this.galcontainerRef.nativeElement;
                this.initLayout();                
            }, 1);
        }        
    }

    public initData() {        
        this.dataReady = false;      
        this.xl = [];  
        this.pictures.forEach(item => this.xl.push(item));
        this.xl.unshift(this.pictures[this.pictures.length-1]);
        this.xl.push(this.pictures[0]);
        this.n = this.pictures.length;
        this.dataReady = true;
    }

    private initLayout() {  
        this.winWidth = window.innerWidth;      
        this.layoutReady = false;
        this.w = this.galwrap.offsetWidth;        
        this.galcontainer.style.transition = "none";
        this.left = this.w;
        setTimeout(() => {this.galcontainer.style.transition = "left 0.3s ease 0s";}, 100);  
        this.step = 0;
        this.moving = false;
        this.layoutReady = true;        
    }

    @HostListener('window:resize', ['$event'])
    onResize(event) {
        if (window.innerWidth != this.winWidth) {
            this.initLayout();
        } 
    }

    public changeCurrentI(i: number): void {
        this.moving = true;
        this.step = i;
        this.left = this.w*(i+1);
    }

    public moveTo(i: number): void {
        if (!this.moving && this.step !== i) {
            this.moving = true;
            this.step = i;
            this.left = this.w*(i+1);
            this.pictureChanged.emit(this.step);
        }        
    }

    public moveLeft(): void {
        this.moveTo(this.step-1);
    }

    public moveRight(): void {
        this.moveTo(this.step+1);
    }

    public onDragStart (event: TouchEvent | MouseEvent): void {
        if (!this.moving) {
            this.startX = event instanceof MouseEvent ? event.clientX : event.touches[0].clientX;
            this.startY = event instanceof MouseEvent ? event.clientY : event.touches[0].clientY;
            this.startXOffsetted = this.startX - this.galcontainer.offsetLeft;	
            
            if (event instanceof MouseEvent) {    // just start moving             
                this.galcontainer.style.transition = "none";
                this.active = true; 
                window.addEventListener("mousemove", this.onDragMove, {passive: false});
                window.addEventListener("mouseup", this.onDragEnd);              
            } else { // detect if moving is more by X than by Y
                window.addEventListener("touchmove", this.onTouchMove);                            
            }
        }    
    }

    public onTouchMove(event: TouchEvent): void {
        this.canClick = false;
        let deltaX = Math.abs(this.startX - event.touches[0].clientX);
        let deltaY = Math.abs(this.startY - event.touches[0].clientY);
        window.removeEventListener("touchmove", this.onTouchMove);

        if (deltaX > deltaY && !this.moving) {            
            this.galcontainer.style.transition = "none";
            this.active = true;
            window.addEventListener("touchmove", this.onDragMove, {passive: false});
            window.addEventListener("touchend", this.onDragEnd);  
        }
    } 

    public onDragEnd (): void {
        window.removeEventListener("mousemove", this.onDragMove);
        window.removeEventListener("touchmove", this.onDragMove);
        window.removeEventListener("mouseup", this.onDragEnd);
        window.removeEventListener("touchend", this.onDragEnd);
        this.galcontainer.style.transition = "left 0.3s ease 0s";
        this.active = false;
        
        // доводка   
        if (this.moving) {
            if (this.prevX < this.startX - 30) {
                this.step++;
            } else if (this.prevX > this.startX + 30) {
                this.step--;
            }
            
            this.left = this.w * (this.step+1);

            this.pictureChanged.emit(this.step);
        }                             
    }    

    public onDragMove (event: TouchEvent | MouseEvent): void { 
        this.canClick = false;       
        event.cancelable ? event.preventDefault() : null;
        this.moving = true;
        const x: number = event instanceof MouseEvent ? event.clientX : event.touches[0].clientX;
        let nextX: number = this.startXOffsetted - x;            
            
        if (nextX > 0 && nextX < (this.xl.length - 1) * this.w) {
            this.left = nextX;
        }            
            
        this.prevX = x;        
    }

    public adjustInfiniteMotion() {
        this.galcontainer.style.transition = "none";            

        if (this.step === -1) {                
            this.step = this.n - 1;
            this.left = (this.step+1) * this.w;                
        }

        if (this.step === this.n) {
            this.step = 0;
            this.left = (this.step+1) * this.w;  
        }

        setTimeout(() => {
            this.galcontainer.style.transition = "left 0.3s ease 0s";
            this.moving = false;                 
        }, 100);        
    }
}
