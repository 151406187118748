import { Component, Input } from "@angular/core";

import { Item } from 'src/app/model/orm/item.model';
import { WishlistService } from "src/app/services/wishlist.service";

@Component({
    selector: "wishlist-toggle",
    templateUrl: "./wishlist-toggle.component.html",
    styleUrls: ["./wishlist-toggle.component.scss"],
})
export class WishlistToggleComponent {
    @Input() item: Item;
    @Input() isOnSingleItem: boolean;

    constructor(
        private wishlistService: WishlistService,
    ) {}

    public getItem(): Item {
        return this.item.variations && this.item.variations.length ? this.item.variations.find(v => v.current) : this.item;
    }

    get hasItem(): boolean {return this.wishlistService.hasItem(this.getItem());}

    get getIsOnSingleItem(): boolean {return this.isOnSingleItem;}

    public toggleWishlist(e) {
        e.stopPropagation();
        e.preventDefault();
        this.wishlistService.toggle(this.getItem())
    }
}
