import { NgModule } from '@angular/core';
import { FormsModule } from "@angular/forms";
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';

import { PipesModule } from '../pipes/pipes.module';
import { MobmenuComponent } from './mobmenu/mobmenu.component';
import { FooterComponent } from './footer/footer.component';
import { NotificationComponent } from './notification/notification.component';
import { GalSimpleComponent } from './gal-simple/galsimple.component';
import { CatItemComponent } from './cat-item/catitem.component';
import { GalItemsComponent } from './gal-items/galitems.component';
import { RangeSliderComponent } from './range-slider/rangeslider.component';
import { CostPerWearSliderComponent } from './cost-per-wear-slider/costperwearslider.component';
import { FilterComponent } from './filter/filter.component';
import { GalItemPicturesComponent } from './gal-itempictures/galitempictures.component';
import { GalItemPicturesPopupComponent } from './gal-itempictures-popup/galitempictures-popup.component';
import { GalPairComponent } from './gal-pair/galpair.component';
import { GalCollectionComponent } from './gal-collection/galcollection.component';
import { WaitlistComponent } from './waitlist/waitlist.component';
import { NosizemodalComponent } from './nosizemodal/nosizemodal.component';
import { WaitlistService } from './waitlist/waitlist.service';
import { StarsComponent } from './stars/stars.component';
import { SizematchComponent } from './sizematch/sizematch.component';
import { AccordionSectionComponent } from './accordion-section/accordionsection.component';
import { SlidemenuComponent } from './slidemenu/slidemenu.component';
import { CartPanelComponent } from './cart-panel/cartpanel.component';
import { CartPanelRecommendedComponent } from './cart-panel/cart-panel-recommended/cart-panel-recommended.component';
import { LoginPanelComponent } from './login-panel/loginpanel.component';
import { RegisterPanelComponent } from './register-panel/registerpanel.component';
import { RecoverPanelComponent } from './recover-panel/recoverpanel.component';
import { CheckboxComponent } from './checkbox/checkbox.component';
import { DummyComponent } from './dummy/dummy.component';
import { RadioComponent } from './radio/radio.component';
import { Header2Component } from './header2/header2.component';
import { WishlistToggleComponent } from './wishlist-toggle/wishlist-toggle.component';
import { CatsSliderComponent } from './cats-slider/catsslider.component';
import { DeleteReservePopupComponent } from './delete-reserve-popup/delete-reserve-popup.component';

@NgModule({
    imports: [
        RouterModule,
        CommonModule,
        FormsModule,
        PipesModule,
    ],
    declarations: [
        MobmenuComponent,
        FooterComponent,
        GalSimpleComponent,
        GalItemsComponent,
        GalItemPicturesComponent,
        GalItemPicturesPopupComponent,
        GalPairComponent,
        GalCollectionComponent,
        NotificationComponent,
        CatItemComponent,
        RangeSliderComponent,
        CostPerWearSliderComponent,
        FilterComponent,
        WaitlistComponent,
      NosizemodalComponent,
        StarsComponent,
        SizematchComponent,
        AccordionSectionComponent,
        SlidemenuComponent,
        CartPanelComponent,
        CartPanelRecommendedComponent,
        LoginPanelComponent,
        RegisterPanelComponent,
        RecoverPanelComponent,
        CheckboxComponent,
        RadioComponent,
        DummyComponent,
        Header2Component,
        WishlistToggleComponent,
        CatsSliderComponent,
        DeleteReservePopupComponent
    ],
    exports: [
        MobmenuComponent,
        FooterComponent,
        GalSimpleComponent,
        GalItemsComponent,
        GalItemPicturesComponent,
        GalItemPicturesPopupComponent,
        GalPairComponent,
        GalCollectionComponent,
        NotificationComponent,
        CatItemComponent,
        RangeSliderComponent,
        CostPerWearSliderComponent,
        FilterComponent,
        WaitlistComponent,
      NosizemodalComponent,
        StarsComponent,
        SizematchComponent,
        AccordionSectionComponent,
        SlidemenuComponent,
        CartPanelComponent,
        CartPanelRecommendedComponent,
        LoginPanelComponent,
        RegisterPanelComponent,
        RecoverPanelComponent,
        CheckboxComponent,
        RadioComponent,
        DummyComponent,
        Header2Component,
        WishlistToggleComponent,
        CatsSliderComponent,
        DeleteReservePopupComponent
    ],
    providers: [
        WaitlistService,
    ]
})
export class CCModule {}
