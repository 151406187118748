import { Component, OnInit, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { Page } from 'src/app/model/orm/page.model';
import { AppService } from 'src/app/services/app.service';
import { PagesRepository } from 'src/app/services/repositories/pages.repository';
import { Lang } from 'src/app/model/orm/lang.model';
import { CartService } from 'src/app/services/cart.service';
import { Cart } from 'src/app/model/cart';
import { CountriesRepository } from 'src/app/services/repositories/countries.repository';
import { Country } from 'src/app/model/orm/country.model';
import { FormHint } from 'src/app/model/formhint';
import { Item } from 'src/app/model/orm/item.model';
import { Currency } from 'src/app/model/orm/currency.model';
import { AuthService } from 'src/app/services/auth.service';
import { User } from 'src/app/model/orm/user.model';
import { IExhaustedItem } from 'src/app/model/dto/exhausteditem.dto';
import { ICartRecord } from 'src/app/model/cartrecord.interface';
import { IOrderResponseDTO } from 'src/app/model/dto/order.response.dto';
import { ILiqpayDTO } from 'src/app/model/dto/liqpay.dto';
import { IMonobankDTO } from 'src/app/model/dto/monobank.dto';

import { StatisticsGoogleService } from 'src/app/services/statistics-google.service';
import { StatisticsFacebookService } from 'src/app/services/statistics-facebook.service';
import { StatisticsConverterService } from 'src/app/services/statistics-converter.service';

import { StatisticsFacebook } from 'src/app/model/statistics_Facebook';
import { StatisticsGoogleCheckoutStep } from 'src/app/model/statistics_GoogleCheckoutStep';
import { StatisticsCheckoutStep } from 'src/app/model/statistics_CheckoutStep';
import { StatisticsGooglePurchase } from 'src/app/model/statistics_GooglePurchase';

import { CurrenciesRepository } from 'src/app/services/repositories/currencies.repository';
import { DataService } from '../../../services/data.service';

@Component({
  selector: 'checkoutcontinue-page',
  templateUrl: './checkoutcontinue.page.html',
  styleUrls: ['./checkoutcontinue.page.scss']
})
export class CheckoutContinuePage implements OnInit, OnDestroy {
  public page: Page = null;
  public pageReady: boolean = false;
  public hint: FormHint = new FormHint();
  public errorEmail: string = '';
  public errorPhone: string = '';
  public phoneMask: string = '+38 (___) ___-__-__';
  public errorName: string = '';
  public errorSurname: string = '';
  public errorAddress: string = '';
  public errorCity: string = '';
  public errorCountry: string = '';

  public errorPromocode: string = '&nbsp;';
  public promocode: string = '';
  public formSending: boolean = false;
  public formMsg: string = '&nbsp;';
  public formError: boolean = false;
  @ViewChild('cartblk', { static: false }) cartblkRef: ElementRef;
  public liqpayFormData: string = '';
  public liqpayFormSignature: string = '';
  @ViewChild('liqpayForm', { static: false }) liqpayFormRef: ElementRef;
  public submitted = false;
  public isValidFlg: boolean = true;
  public isDeleteReservePopupOpen: boolean = false;

  public saleSetting: any = null;

  public newTimer = {
    minutes: 0,
    seconds: 0
  };
  private timerSetInterval;

  constructor(
    private dataService: DataService,
    private appService: AppService,
    private route: ActivatedRoute,
    private router: Router,
    private pagesRepository: PagesRepository,
    private cartService: CartService,
    private countriesRepository: CountriesRepository,
    private authService: AuthService,
    private statisticsGoogleService: StatisticsGoogleService,
    private statisticsFacebookService: StatisticsFacebookService,
    private statisticsConverterService: StatisticsConverterService,
    private currenciesRepository: CurrenciesRepository,
  ) {
  }

  get currentLang(): Lang {
    return this.appService.currentLang.value;
  }

  get currentCurrency(): Currency {
    return this.appService.currentCurrency.value;
  }

  get cart(): Cart {
    return this.cartService.cart;
  }

  get countries(): Country[] {
    return this.countriesRepository.xl;
  }

  get subTotal(): number {
    return this.cartService.sum;
  }

  get shippingCost(): number {
    return this.cartService.shippingCost;
  }

  get freeShippingLimit(): number {
    return this.cartService.freeShippingLimit;
  }

  get discount(): number {
    return this.cartService.discount;
  }

  get total(): number {
    return this.cartService.total;
  }

  get user(): User {
    return this.authService.user;
  }

  get bagLabel(): string {
    return `${ this.currentLang.s['cart']['bag'] } (${ this.cartService.bagCost } ${ this.currentCurrency.name })`;
  }

  get currentCountry(): Country {
    return this.cartService.currentCountry;
  }

  get cartQ(): number {
    return this.cartService.q;
  }

  get cartS(): number {
    return this.cartService.sum;
  }

  get currencies(): Currency[] {
    return this.currenciesRepository.xl;
  }

  public ngOnInit(): void {
    this.appService.headerAlwaysOpaque = true;
    this.appService.catmenumobileActive = false;
    this.appService.collmenumobileActive = false;
    this.initCartUserData();

    this.dataService.getSaleSettingsData().subscribe(response => {
      if (response) {
        this.saleSetting = response.data;
      }
    });

    // page
    this.page = this.pagesRepository.xl.find(x => x.slug === 'checkout-continue') || null;

    if (!this.page) {
      this.router.navigateByUrl(`/${ this.currentLang.slug }/404`);
    } else {
      this.pageReady = true;
      this.route.params.subscribe(p => {
        this.appService.setTitle(this.page.title[this.currentLang.slug] || this.page.name[this.currentLang.slug]);
        this.sendCheckoutContinueEvent();
      });
    }
    this.validatePhoneNo();

    this.initializeTimer();
  }

  public ngOnDestroy(): void {
    clearInterval(this.timerSetInterval);
    this.deleteReserve();
  }

  public onDeleteReserve(): void {
    this.deleteReserve();
    this.router.navigateByUrl('/' + this.currentLang.slug + '/shop/cart');
  }

  public deleteReserve(): void {
    this.dataService.deleteReserve(this.cartService.cartUserId).subscribe(
      response => {
        this.cartService.clear();

        // this.router.navigateByUrl('/');
      },
      error => {
        console.log('Something went wrong!');
      }
    );
  }

  initializeTimer() {
    const endTimerTime = this.cart.newTimer;
    const timeNow = new Date().getTime();
    let timeToStopTimer = Math.floor((endTimerTime - timeNow) / 1000);

    this.newTimer.minutes = Math.floor(timeToStopTimer / 60);
    this.newTimer.seconds = timeToStopTimer - this.newTimer.minutes * 60;

    this.timerSetInterval = setInterval(() => {
      if (timeToStopTimer >= 0) {
        this.newTimer.minutes = Math.floor(timeToStopTimer / 60);
        this.newTimer.seconds = timeToStopTimer - this.newTimer.minutes * 60;

        timeToStopTimer--;
      } else {
        this.cartService.addCartInfoMessage.emit({
            message: 'Ваш резерв скасовано.',
            showBtn: false,
            show: true
        });

        this.deleteReserve();
        clearInterval(this.timerSetInterval);
        this.router.navigateByUrl('/');
      }

      if (timeToStopTimer < 120 && timeToStopTimer > 115) {
        this.cartService.addCartInfoMessage.emit({
            message: 'Ваш резерв майже закінчився.',
            showBtn: false,
            show: true
        });
      }
    }, 1000);
  }

  sendCheckoutContinueEvent() {
    let step = StatisticsCheckoutStep.CHECKOUT_CONTINUE;
    let num_items = this.cartQ.toString();

    let facebookCurrency: Currency;
    for (let i = 0; i < this.currencies.length; i++) {
      if (this.currencies[i].name !== 'UAH') {
        facebookCurrency = this.currencies[i];
        break;
      }
    }
    let facebookPrice = Math.ceil(this.cartS * facebookCurrency.rate);

    let googleData: StatisticsGoogleCheckoutStep
      = this.statisticsConverterService.toStatisticsGoogleCheckoutStep(
      step,
      this.currentCurrency.name,
      this.cart.records,
      this.currentLang.slug);
    let facebookData: StatisticsFacebook
      = this.statisticsConverterService.toStatisticsFacebookCheckoutInitiated(
      this.cart.records,
      this.currentLang.slug,
      facebookCurrency.name,
      facebookPrice.toString(),
      num_items);

    this.statisticsGoogleService.checkoutStep(googleData);
    this.statisticsFacebookService.initiateCheckout(facebookData);
  }

  sendCheckoutFinishEvent() {
    let step = StatisticsCheckoutStep.CHECKOUT_FINISH;
    let cart_sum = this.cartS.toString();

    let googleData: StatisticsGoogleCheckoutStep
      = this.statisticsConverterService.toStatisticsGoogleCheckoutStep(
      step,
      this.currentCurrency.name,
      this.cart.records,
      this.currentLang.slug);

    this.statisticsGoogleService.checkoutStep(googleData);
  }

  sendPurchaseEvent(transactionId: any) {
    let coupon = this.promocode;
    let num_items = this.cartQ.toString();
    let cart_sum = this.cartS.toString();

    let facebookCurrency: Currency;
    for (let i = 0; i < this.currencies.length; i++) {
      if (this.currencies[i].name !== 'UAH') {
        facebookCurrency = this.currencies[i];
        break;
      }
    }
    let facebookPrice = Math.ceil(this.cartS * facebookCurrency.rate);

    let googleData: StatisticsGooglePurchase
      = this.statisticsConverterService.toStatisticsGooglePurchase(
      this.currentCurrency.name,
      this.cart.records,
      this.currentLang.slug,
      '' + transactionId,
      cart_sum,
      coupon
    );

    let facebookData: StatisticsFacebook
      = this.statisticsConverterService.toStatisticsFacebookPurchase(
      this.cart.records,
      this.currentLang.slug,
      facebookCurrency.name,
      facebookPrice.toString(),
      num_items);

    this.statisticsGoogleService.purchase(googleData);
    this.statisticsFacebookService.purchase(facebookData);
  }

  public focusPhone(el) {
    el.previousSibling.focus()
    el.parentElement.previousSibling.focus()
  }

  public validatePhoneNo(target = null) {
    if (this.cart.phone === '') {
      return;
    }

    let phoneNumDigits = this.cart.phone.replace(/\D/g, '').substr(0, 12);
    if ((this.cart.country_id && this.cart.country_id !== 2)) {
      this.cart.phone = '+' + phoneNumDigits;
    } else {
      if (phoneNumDigits.substring(0, 2) === '38') {
        phoneNumDigits = phoneNumDigits.substring(2);
      }

      // this.isValidFlg = (phoneNumDigits.length === 0 || phoneNumDigits.length === 10);

      this.errorPhone = ''

      if (phoneNumDigits !== '') {
        let formattedNumber = phoneNumDigits;

        if (phoneNumDigits.length > 8) {
          formattedNumber = '+38 (' + formattedNumber.substr(0, 3) + ') ' + formattedNumber.substr(3, 3) + '-' + formattedNumber.substr(6, 2) + '-' + formattedNumber.substr(8, 2);
        } else if (phoneNumDigits.length === 8 || phoneNumDigits.length === 7) {
          formattedNumber = '+38 (' + formattedNumber.substr(0, 3) + ') ' + formattedNumber.substr(3, 3) + '-' + formattedNumber.substr(6, 2);
        } else if (phoneNumDigits.length <= 6 && phoneNumDigits.length >= 4) {
          formattedNumber = '+38 (' + formattedNumber.substr(0, 3) + ') ' + formattedNumber.substr(3, 3);
        } else {
          formattedNumber = '+38 (' + formattedNumber.substr(0, 3);
        }


        this.cart.phone = formattedNumber;
        // if (phoneNumDigits.length <= 3) {
        // 	formattedNumber = '+38 ' + phoneNumDigits.substring(0, 3);
        // }
        // if (phoneNumDigits.length <= 6 && phoneNumDigits.length > 3) {
        // 	formattedNumber = '+38 ' + phoneNumDigits.substring(0, 3) + ' ' + phoneNumDigits.substring(3, 6);
        // }
        // if (phoneNumDigits.length <= 8 && phoneNumDigits.length > 6) {
        // 	formattedNumber = '+38 ' + phoneNumDigits.substring(0, 3) + ' ' + phoneNumDigits.substring(3, 6) + ' ' + phoneNumDigits.substring(6, 8);
        // }
        // if (phoneNumDigits.length > 8) {
        // 	formattedNumber = '+38 ' + phoneNumDigits.substring(0, 3) + ' ' + phoneNumDigits.substring(3, 6) + ' ' + phoneNumDigits.substring(6, 8) + ' ' + phoneNumDigits.substring(8);
        // }

        // if (formattedNumber.length > 17) {
        // 	field.value = formattedNumber.slice(0, 17);
        // 	return;
        // } else {
        // 	field.value = formattedNumber;
        // }
      } else {
        this.cart.phone = '';
      }
      let slicedMask = '+38 (___) ___-__-__'.slice(this.cart.phone.length);
      let preSlicedMask = '';
      console.log(19 - slicedMask.length)
      for (let i = 0; i <= 18 - slicedMask.length; i++) {
        if (i == 0) {
          preSlicedMask += '<span class="opacity-0" (click)="focusPhone($event.target)">+</span>';
        } else if (i == 3 || i == 9) {
          preSlicedMask += '<span class="opacity-0" (click)="focusPhone($event.target)"> </span>';
        } else if (i == 4) {
          preSlicedMask += '<span class="opacity-0" (click)="focusPhone($event.target)">(</span>';
        } else if (i == 8) {
          preSlicedMask += '<span class="opacity-0" (click)="focusPhone($event.target)">)</span>';
        } else if (i == 13 || i == 16) {
          preSlicedMask += '<span class="opacity-0" (click)="focusPhone($event.target)">-</span>';
        } else {
          preSlicedMask += '<span class="opacity-0" (click)="focusPhone($event.target)">_</span>';
        }
      }
      this.phoneMask = preSlicedMask + slicedMask;
    }

    if (target) target.value = this.cart.phone
  }

  private initCartUserData(): void {
    if (this.authService.authenticated) {
      this.cart.email = this.authService.user.email;
      this.cart.phone = this.authService.user.phone;

      this.cart.name = this.authService.user.name;
      this.cart.surname = this.authService.user.surname;
      this.cart.address = this.authService.user.address;
      this.cart.city = this.authService.user.city;
      this.cart.province = this.authService.user.province;
      this.cart.country_id = this.authService.user.country_id;
      this.cartService.currentCountry = this.countriesRepository.xl.find(x => x.id === this.cart.country_id);
    } else {
      if (!this.cart.country_id) {
        let homeCountry: Country = this.countriesRepository.xl.find(x => x.home) || null;

        if (homeCountry) {
          this.cart.country_id = homeCountry.id;
          this.cartService.currentCountry = homeCountry;
        }
      }
    }

    if (this.cartService.currentCountry && !this.cartService.currentCountry.home) {
      this.cart.paymentmethod_id = 1;
    }

    this.cartService.isCheckout = true;

    this.saveCart();
  }

  public async finish() {
    this.submitted = true;


    if (!this.validate()) {
      this.saveCart(false);
      return false;
    }

    try {
      this.formSending = true;
      this.formError = false;
      this.formMsg = this.currentLang.s['checkout']['order-prepare'];
      let res: IOrderResponseDTO = await this.cartService.sendOrder(this.currentLang.id, this.user ? this.user.id : null, this.currentCurrency.id);

      this.saveCart();

      if (res.orderId) { // order saved
        this.formMsg = this.currentLang.s['checkout']['order-notify'];
        await this.cartService.sendOrderNotification(res.orderId);
        this.cartService.sendOrderToMoysklad(res.orderId);


        if (this.cart.paymentmethod_id === 1 || this.cart.paymentmethod_id === 2) { // online
          if (res.paymentSystem == 'monobank') {
            this.formMsg = this.currentLang.s['checkout']['payment-prepare'];
            let monobankData: IMonobankDTO = await this.cartService.getMonobankData(res.orderId);
            return window.location.href = monobankData.pageUrl;
          } else {
            this.formMsg = this.currentLang.s['checkout']['payment-prepare'];
            let liqpayData: ILiqpayDTO = await this.cartService.getLiqpayData(res.orderId);
            this.liqpayFormData = liqpayData.formData;
            this.liqpayFormSignature = liqpayData.formSignature;
            this.formSending = false;
            this.formMsg = '&nbsp;';
            this.sendCheckoutFinishEvent();
            this.sendPurchaseEvent(res.orderId);
            // send hidden form to payment service
            setTimeout(() => {
              let form: HTMLFormElement = this.liqpayFormRef.nativeElement;
              form.submit();
              this.cartService.clear();
            }, 1);
          }
        } else if (this.cart.paymentmethod_id === 2) { // cash in post office
          this.formSending = false;
          this.formMsg = '&nbsp;';
          this.sendCheckoutFinishEvent();
          this.sendPurchaseEvent(res.orderId);
          this.router.navigateByUrl(`/${ this.currentLang.slug }/shop/checkout-finish/${ res.orderId }`);
          this.cartService.clear();
        }
      } else { // some items not in stock
        this.formSending = false;
        this.formMsg = this.currentLang.s['checkout']['notenough'];
        this.formError = true;
        let exhaustedItems: IExhaustedItem[] = res.exhaustedItems;
        this.adjustMaximums(exhaustedItems);
        window.innerWidth <= 1000 ? this.scrollToCartblk() : null;
        setTimeout(() => {
          this.formMsg = '&nbsp;';
          this.formError = false;
        }, 5000);
      }
    } catch (err) {
      this.appService.showNotification(err, 'error');
      this.formSending = false;
      this.formMsg = '&nbsp;';
    }
  }

  private validate(): boolean {
    let error: boolean = false;
    this.cart.email = this.cart.email.trim();

    if (!this.cart.email.length) {
      error = true;
      this.errorEmail = this.currentLang.s['common']['error-required'];
    } else if (!this.appService.validateEmail(this.cart.email)) {
      error = true;
      this.errorEmail = this.currentLang.s['common']['error-emailincorrect'];
    } else {
      this.errorEmail = '';
    }

    if ((this.cart.country_id && this.cart.country_id === 2 && (this.cart.phone.replace(/\D/g, '') + '').length < 12) || !this.cart.phone.length) {
      error = true;
      if (!this.cart.phone.length) {
        this.errorPhone = this.currentLang.s['common']['error-required'];
      } else {
        this.errorPhone = this.currentLang.s['common']['incorrect-phone'];
      }
    } else {
      this.errorPhone = '';
    }


    if (!this.cart.name.length) {
      error = true;
      this.errorName = this.currentLang.s['common']['error-required'];
    } else {
      this.errorName = '';
    }

    if (!this.cart.surname.length) {
      error = true;
      this.errorSurname = this.currentLang.s['common']['error-required'];
    } else {
      this.errorSurname = '';
    }

    if (!this.cart.address.length) {
      error = true;
      this.errorAddress = this.currentLang.s['common']['error-required'];
    } else {
      this.errorAddress = '';
    }

    if (!this.cart.city.length) {
      error = true;
      this.errorCity = this.currentLang.s['common']['error-required'];
    } else {
      this.errorCity = '';
    }

    if (this.cart.country_id === null) {
      error = true;
      this.errorCountry = this.currentLang.s['common']['error-required'];
    } else {
      this.errorCountry = '';
    }


    if (this.errorEmail || this.errorPhone) {
      this.appService.smoothScroll(window.scrollY, 0, 500, window);
    }

    return !error;
  }

  public dontCallMeChanged(): void {

  }

  public getItemImg(item: Item): string {
    return item.pictures[0].img_s ? `url(/images/${ item.pictures[0].img_s })` : `url(/images/noimage.jpg)`;
  }

  public getItemPrice(item: Item): number {
    return Math.ceil(item.price * this.currentCurrency.rate);
  }

  private scrollToCartblk(): void {
    let elem: HTMLElement = this.cartblkRef.nativeElement;
    this.appService.smoothScroll(window.scrollY, elem.offsetTop - 80, 500, window);
  }

  public decrease(r: ICartRecord): void {
    this.cartService.decreaseRecord(r);
  }

  public increase(r: ICartRecord): void {
    this.cartService.increaseRecord(r);
  }

  public remove(r: ICartRecord): void {
    this.cartService.removeRecord(r);
  }

  public saveCart(clearDontCall = true): void {
    this.cartService.save(clearDontCall);
  }

  private adjustMaximums(exhaustedItems: IExhaustedItem[]): void {
    for (let ei of exhaustedItems) {
      let record: ICartRecord = this.cart.records.find(r => r.item.id === ei.item_id);
      record ? record.size.q = ei.max_q : null;
    }
  }

  public async applyPromocode(): Promise<void> {
    try {
      this.promocode = this.promocode.trim();

      if (this.promocode.length) {
        let status: number = await this.cartService.applyPromocode(this.promocode);

        if (status === 409 || status === 404) {
          this.errorPromocode = this.currentLang.s['checkout']['promo-error'];
          setTimeout(() => this.errorPromocode = '&nbsp;', 3000);
        }
      }
    } catch (err) {
      this.appService.showNotification(err, 'error');
    }
  }

  public onPromocodeKeyDown(event: KeyboardEvent): void {
    let key: number = event.which || event.keyCode || 0;

    if (key === 13) {
      event.preventDefault();
      this.applyPromocode();
    }
  }

  public onCountryChanged(): void {
    this.cartService.currentCountry = this.countriesRepository.xl.find(x => x.id === this.cart.country_id) || null;
    (!this.cartService.currentCountry || !this.cartService.currentCountry.home) ? this.cart.paymentmethod_id = 1 : null;

    if (this.cart.phone && this.cart.country_id === 2) {
      console.log(this.cart.phone)
      let phoneNumDigits = this.cart.phone.replace(/\D/g, '');

      if (phoneNumDigits.substring(0, 2) === '38') {
        phoneNumDigits = phoneNumDigits.substring(2);
      }

      this.isValidFlg = (phoneNumDigits.length === 0 || phoneNumDigits.length === 10);

      let formattedNumber = phoneNumDigits;

      if (phoneNumDigits.length <= 3) {
        formattedNumber = '+38 ' + phoneNumDigits.substring(0, 3);
      }
      if (phoneNumDigits.length <= 6 && phoneNumDigits.length > 3) {
        formattedNumber = '+38 ' + phoneNumDigits.substring(0, 3) + ' ' + phoneNumDigits.substring(3, 6);
      }
      if (phoneNumDigits.length <= 8 && phoneNumDigits.length > 6) {
        formattedNumber = '+38 ' + phoneNumDigits.substring(0, 3) + ' ' + phoneNumDigits.substring(3, 6) + ' ' + phoneNumDigits.substring(6, 8);
      }
      if (phoneNumDigits.length > 8) {
        formattedNumber = '+38 ' + phoneNumDigits.substring(0, 3) + ' ' + phoneNumDigits.substring(3, 6) + ' ' + phoneNumDigits.substring(6, 8) + ' ' + phoneNumDigits.substring(8);
      }

      if (formattedNumber.length > 17) {
        this.cart.phone = formattedNumber.slice(0, 17);
        return;
      } else {
        this.cart.phone = formattedNumber;
      }

      console.log(this.cart.phone)
    }
  }

  public timeFilter(number: number): string {
    let string = number < 10 ? '0' + number : number + '';
    return string;
  }
}
