<div id="mobmenu" [ngClass]="{'active': mobmenuActive}">
    <div id="mm-left" (click)="mobmenuActive = false;"></div>
    <div id="mm-right">
        <div id="mm-head">
            <div id="mm-logo"><a [routerLink]="currentLang.defended ? '/' : '/'+currentLang.slug" (click)="mobmenuActive = false;"><img src="/images/templates/visitor/logo-chocolate.svg"></a></div>
            <div id="mm-close" (click)="mobmenuActive = false;"></div>
            <div id="mm-dash">
              <div class="wishlist" [class.wishlist-not-empty]="wishlist.records.length" (click)="mobmenuActive = false;">
                <a routerLink="/{{currentLang.slug}}/shop/wishlist">
                  <svg *ngIf="!wishlist.records.length" width="22px" height="100%" fill="transparent" viewBox="0 0 1054.86 965.31">
                    <defs>
                      <style>.cls-h-empty {
                        stroke: black;
                        stroke-miterlimit: 10;
                        stroke-width: 34px;
                      }</style>
                    </defs>
                    <path class="cls-h-empty"
                          d="M1039.66,296.18h0c.15-3.51.28-7,.28-10.58,0-142.47-120.16-266-268.39-266-108.37,0-201.68,69.78-244,158.66h-.06C485,89.41,391.72,19.63,283.35,19.63,135.12,19.63,15,143.13,15,285.6c0,3.55.13,7.07.28,10.58h0S.05,465.43,188.4,650.21C274,736,369.8,817.55,527.43,945.67c157.63-128.12,253.4-209.66,339-295.46C1054.81,465.43,1039.66,296.18,1039.66,296.18Z"/>
                  </svg>
                  <svg *ngIf="wishlist.records.length" width="20px" height="18px" fill="#cd2027" viewBox="0 0 1054.86 965.31">
                    <defs>
                      <style>.cls-h-filled {
                        stroke: #cd2027;
                        stroke-miterlimit: 10;
                        stroke-width: 34px;
                      }</style>
                    </defs>
                    <path class="cls-h-filled" d="M1039.66,296.18h0c.15-3.51.28-7,.28-10.58,0-142.47-120.16-266-268.39-266-108.37,0-201.68,69.78-244,158.66h-.06C485,89.41,391.72,19.63,283.35,19.63,135.12,19.63,15,143.13,15,285.6c0,3.55.13,7.07.28,10.58h0S.05,465.43,188.4,650.21C274,736,369.8,817.55,527.43,945.67c157.63-128.12,253.4-209.66,339-295.46C1054.81,465.43,1039.66,296.18,1039.66,296.18Z"/>
                    <animateTransform
                      attributeName="transform"
                      type="scale"
                      values="1; 1.3; 1.15; 1.3; 1.15; 1;"
                      dur="1s"
                      repeatCount="1">
                    </animateTransform>
                  </svg>
                </a>
              </div>
                <div id="mmd-cart" (click)="mobmenuActive = false; cartpanelActive = true;"></div>
            </div>
        </div>
        <div id="mm-gal" *ngIf="gal && gal.items.length">  
            <div id="mm-galcontainer">
                <a *ngFor="let gi of gal.items" href="{{gi.link[currentLang.slug]}}">
                    <div class="mm-galitem">
                        <div class="mmgi-img" [style.background-image]="'url(/images/gals/'+gi.img_s+')'"></div>
                        <div class="mmgi-title">{{gi.header[currentLang.slug]}}</div>
                    </div>
                </a>                              
            </div>                  
        </div>
        <div id="mm-shopmenu">            
            <div class="mm-shopmenuitem" *ngFor="let c of topcats"><a routerLink="/{{currentLang.slug}}/shop/{{c.slug}}" (click)="mobmenuActive = false;">{{c.name[currentLang.slug]}}</a></div>
            <ng-container *ngFor="let p of pagesMain">
                <div class="mm-shopmenuitem" [class.expanded]="(p.slug === 'shop' && catmenuActive) || (p.slug === 'collections' && collmenuActive) || (p.slug === 'collections1' && collmenuActive1)">                
                    <!-- simple link -->
                    <a *ngIf="p.slug !== 'shop' && p.slug !== 'collections' && p.slug !== 'collections1'" [routerLink]="'/'+currentLang.slug+'/'+p.slug" (click)="mobmenuActive = false;">{{p.name[currentLang.slug]}}</a>
                    <!-- shop -->
                    <a *ngIf="p.slug === 'shop'" (click)="catmenuActive = !catmenuActive; collmenuActive = false;collmenuActive1 = false;">{{p.name[currentLang.slug]}}</a>
                    <div *ngIf="p.slug === 'shop'" class="more" [class.active]="catmenuActive"></div>
                    <!-- collections -->
                    <a *ngIf="p.slug === 'collections'" (click)="collmenuActive = !collmenuActive; catmenuActive = false;collmenuActive1 = false;">{{p.name[currentLang.slug]}}</a>
                    <div *ngIf="p.slug === 'collections'" class="more" [class.active]="collmenuActive"></div>

                    <a *ngIf="p.slug === 'collections1'" (click)="collmenuActive1 = !collmenuActive1; catmenuActive = false;collmenuActive = false">{{p.name[currentLang.slug]}}</a>
                    <div *ngIf="p.slug === 'collections1'" class="more" [class.active]="collmenuActive1"></div>
                </div>
                <!-- categories menu -->
                <div *ngIf="p.slug === 'shop'" class="mm-catmenu" [class.active]="catmenuActive">
                    <div class="mm-catmenucontent">
                        <div class="mm-catmenuitem" *ngFor="let c of cats"><a routerLink="/{{currentLang.slug}}/shop/{{c.slug}}" (click)="mobmenuActive = false;">{{c.name[currentLang.slug]}}</a></div>                        
                    </div>                
                </div>

                
                <!-- collections menu -->
                <div *ngIf="p.slug === 'collections'" class="mm-catmenu" [class.active]="collmenuActive">
                    <div class="mm-catmenucontent">
                        <div class="mm-catmenuitem" *ngFor="let c of collections"><a routerLink="/{{currentLang.slug}}/collections/{{c.slug}}" (click)="mobmenuActive = false;">{{c.name[currentLang.slug]}}</a></div>                        
                    </div>                
                </div>

                <div *ngIf="p.slug === 'collections1'" class="mm-catmenu" [class.active]="collmenuActive1">
                    <div class="mm-catmenucontent">
                        <div class="mm-catmenuitem" *ngFor="let c of cats1;let i = index" [class.current]="i === 0"><a routerLink="/{{currentLang.slug}}/shop/{{c.slug}}" (click)="mobmenuActive = false;">{{c.name[currentLang.slug]}}</a></div>                        
                    </div>                
                </div>
            </ng-container>            
        </div>
        <div id="mm-pagemenu">
            <ul>
                <li *ngIf="authenticated && false"><a routerLink="/{{currentLang.slug}}/account/private" (click)="mobmenuActive = false;">{{currentLang.s['user']['acc']}}</a></li>
                <li *ngIf="!authenticated && false"><a (click)="mobmenuActive = false; loginpanelActive = true;">{{currentLang.s['user']['login']}}</a></li>
                <li *ngFor="let p of pagesAdditional"><a [routerLink]="'/'+currentLang.slug+'/'+p.slug" (click)="mobmenuActive = false;">{{p.name[currentLang.slug]}}</a></li>
            </ul>
        </div>
        <div id="mm-foot">
            <div *ngIf="false" id="mm-langs">
                <div id="mm-langsmark"><span></span></div>
                <ul>
                    <li *ngFor="let l of langs"><a [class.active]="l.id === currentLang.id" [routerLink]="getLangLink(l.slug)">{{l.title}}</a></li>                                                
                </ul>
            </div>
            <div id="mm-currencies">
                <div id="mm-currenciesmark"><span></span></div>
                <ul>
                    <li *ngFor="let c of currencies" (click)="setCurrency(c);"><span [class.active]="c.id === currentCurrency.id">{{c.name}}</span></li>                    
                </ul>
            </div>
        </div>
    </div>
</div>
