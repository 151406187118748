import { Component, Input } from "@angular/core";
import { Router } from '@angular/router';

import { Item } from 'src/app/model/orm/item.model';
import { AppService } from 'src/app/services/app.service';
import { Lang } from 'src/app/model/orm/lang.model';
import { Currency } from 'src/app/model/orm/currency.model';

@Component({
    selector: "cat-item",
    templateUrl: "./catitem.component.html",
    styleUrls: ["./catitem.component.scss"],
})
export class CatItemComponent {
    @Input() item: Item;
    @Input() hoverable: boolean = true;
    @Input() blockImageLink: boolean = false;
    @Input() compact: boolean = false;

    constructor(
        private appService: AppService,
        private router: Router,
    ) {}

    get currentLang(): Lang {return this.appService.currentLang.value;}
    get currentCurrency(): Currency {return this.appService.currentCurrency.value;}
    get price(): number {return Math.ceil(this.getItem().price * this.currentCurrency.rate);}
    get link(): string {return `/${this.currentLang.slug}/shop/${this.item.catslug}/${this.getItem().slug}`;}
    get getMainImg(): string {
      let index = this.appService.currentImageView === 'model' ? 0 : (this.getItem().pictures.length-1);
      return this.getImg(index);
    }
    get getSecondaryImg(): string {
      let index = this.appService.currentImageView === 'model' ? (this.getItem().pictures.length-1) : 0;
      return this.getImg(index);
    }

    public getItem(): Item {
        return this.item.variations && this.item.variations.length ? this.item.variations.find(v => v.current) : this.item;
    }

    public getImg(n: number): string {
        let img: string = "/images/noimage.jpg";
        let item: Item = this.getItem();

        if (item.pictures[n]) {
            img = "/images/" + item.pictures[n].img_s;
        }

        return `url('${img}')`;
    }

    public goLink(): void {
        if (!this.blockImageLink) { // в некоторых галереях при движении ссылка на картинке блокируется, чтобы дать возможность послайдтить мышью или пальцем
            this.router.navigateByUrl(this.link);
        }
    }

    public activateVariation(v: Item): void {
        this.item.variations.forEach(v => v.current = false);
        v.current = true;
    }
}
